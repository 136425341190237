import { BASE_URL, IS_MOCK_API } from '../constants';

interface LoginResponse {
  token: string;
}

interface LoginParams {
  wallet_address: string;
  referrer_address: string;
  message: string;
  signature: string;
}

const login = async (params: LoginParams): Promise<LoginResponse> => {
  if (IS_MOCK_API) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          token: 'mockToken',
        });
        window.localStorage.setItem('token', 'mockToken');
      }, 2000);
    });
  }
  const response = await fetch(`${BASE_URL}/v1/account/login`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  const data = await response.json();
  window.localStorage.setItem('token', data.token);
  return data;
};

export default login;
