import { BASE_URL, IS_MOCK_API } from '../constants';

interface GetNonceResponse {
  nonce: string;
}

const getNonce = async (): Promise<GetNonceResponse> => {
  if (IS_MOCK_API) {
    return { nonce: 'mockNonce' };
  }
  const response = await fetch(`${BASE_URL}/v1/account/challenge`);

  if (!response.ok) {
    throw new Error('Get Nonce failed');
  }

  const data = await response.json();
  return data;
};

export default getNonce;
