import { defineChain } from 'viem';

export const storyOdysseyTestnet = defineChain({
  id: 1516,
  name: 'Story Odyssey Testnet',
  nativeCurrency: {
    name: 'IP',
    symbol: 'IP',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['https://rpc.odyssey.storyrpc.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Story Explorer',
      url: 'https://odyssey.storyscan.xyz',
    },
  },
});
