import { extendTheme } from '@chakra-ui/react';

const theme = {
  fonts: {
    heading: `'Satoshi', sans-serif`,
    body: `'Satoshi', sans-serif`,
  },
  colors: {
    primary: '#28E6FF',
    secondary: '#FAF582',
    alert: '#FF5151',
    gray: '#BDBDBD',
    borderColor: '#404040',
    text: {
      primary: '#0A0A0A',
    },
  },
  styles: {
    global: {
      'html, body': {
        margin: 0,
        bg: '#0A0A0A',
      },
      body: { margin: '0px', color: 'text.primary' },
    },
  },
  components: {
    Link: {
      baseStyle: {
        _hover: { textDecoration: 'unset' },
      },
    },
    Button: {
      sizes: {
        s: {
          p: '4px 8px',
        },
        m: {
          p: '8px 16px',
        },
      },
      variants: {
        primary: {
          bg: '#28E6FF',
          color: '#0a0a0a',
          borderRadius: '24px',
          p: '4px 8px',
          _disabled: {
            bg: '#333333',
            color: '#757575',
            cursor: 'not-allowed',
          },
          _hover: {
            bg: '#1CA1B3',
            color: '#0A0A0A',
            _disabled: {
              bg: '#333333',
              color: '#757575',
              cursor: 'not-allowed',
            },
          },
        },
        outline: {
          bg: 'transparent',
          border: '1px solid #28E6FF',
          color: '#28E6FF',
          borderRadius: '24px',
          p: '4px 8px',
        },
      },
    },
    Tag: {
      baseStyle: {
        container: {
          bg: 'rgba(40, 230, 255, 0.20)',
          borderRadius: '24px',
          p: '4px 8px',
          color: '#28E6FF',
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '20px',
          width: 'fit-content',
        },
      },
      variants: {
        secondary: {
          container: {
            bg: '#fff',
            color: '#0A0A0A',
            fontSize: '14px',
            width: 'fit-content',
          },
        },
        disabled: {
          container: {
            bg: '#333333',
            color: '#757575',
            fontSize: '14px',
            width: 'fit-content',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: 'rgba(40, 230, 255, 0.10)',
          backdropFilter: 'blur(10px)',
          color: 'white',
          borderRadius: '16px',
          overflow: 'hidden',
          border: '1px solid #404040',
          padding: '0',
        },
        item: {
          bg: '#22242B',
          height: '48px',
          gap: '8px',
        },
        divider: {
          my: '0',
        },
      },
    },
  },
};

export default extendTheme(theme);
