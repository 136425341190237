import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import customTheme from './@chakra-ui/gatsby-plugin/theme';
import Fonts from './Fonts';
import { WagmiProvider } from 'wagmi';
import { config } from './wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  AuthenticationStatus,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  createAuthenticationAdapter,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { createSiweMessage } from 'viem/siwe';
import { extractEthereumAccount } from './utils/extractEthereumAccount';
import loginApi from './apis/login';
import getNonce from './apis/getNonce';
// Need to import the css file with module name and use it, or it's not working somehow
// @ts-ignore
import style from '@rainbow-me/rainbowkit/styles.css';

const RootWrapper = ({ element }: { element: React.ReactNode }) => {
  const [authStatus, setAuthStatus] =
    React.useState<AuthenticationStatus>('unauthenticated');

  // example :https://github.com/rainbow-me/rainbowkit/blob/main/examples/with-next-siwe-iron-session/src/pages/_app.tsx
  const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      const { nonce } = await getNonce();
      return nonce;
    },
    createMessage: ({ nonce, address, chainId }) => {
      return createSiweMessage({
        domain: window.location.host,
        address: address as `0x${string}`,
        statement: `I'm ready to onboard Spotlight Protocol with Story!`,
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce,
      });
    },
    getMessageBody: ({ message }) => {
      return message;
    },
    verify: async ({ message, signature }) => {
      // can't get address from verify function
      const wallet_address = extractEthereumAccount(message);
      const searchParams = new URLSearchParams(window.location.search);
      const referrer_address = searchParams.get('utm_source') || '';
      const verifyResult = await loginApi({
        message,
        signature,
        wallet_address: wallet_address || '',
        referrer_address,
      });
      if (verifyResult?.token) {
        setAuthStatus('authenticated');
        return true;
      }
      return false;
    },
    signOut: async () => {
      setAuthStatus('unauthenticated');
      window.localStorage.removeItem('token');
      await new Promise((r) => setTimeout(r, 100));
    },
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={new QueryClient()}>
        <RainbowKitAuthenticationProvider
          adapter={authenticationAdapter}
          status={authStatus}
        >
          <RainbowKitProvider
            theme={darkTheme()}
            modalSize="compact"
            appInfo={{
              appName: 'Spotlight Protocol',
            }}
          >
            <ChakraProvider theme={customTheme}>
              <Fonts />
              <div style={style}>{element}</div>
            </ChakraProvider>
          </RainbowKitProvider>
        </RainbowKitAuthenticationProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return <RootWrapper element={element} />;
};
