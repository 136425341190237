import { http } from 'wagmi';
import { Chain } from 'wagmi/chains';
import { storyOdysseyTestnet } from '../constants/chain';
const CHAINS_CONFIG = [storyOdysseyTestnet];

const TYPED_CHAINS = CHAINS_CONFIG as unknown as Readonly<
  typeof CHAINS_CONFIG
> &
  readonly [Chain, ...Chain[]];

export type BentoChainType = (typeof TYPED_CHAINS)[number];

const chainIdRpcMapping: {
  [key in (typeof TYPED_CHAINS)[number]['id']]?: string;
} = {
  [storyOdysseyTestnet.id]: 'https://rpc.odyssey.storyrpc.io',
};

export const chainIdChainMapping = CHAINS_CONFIG.reduce<
  Record<number, BentoChainType>
>((acc, chain) => ({ ...acc, [chain.id]: chain }), {});

const INFURA_KEY = process.env.NEXT_PUBLIC_INFURA_KEY;

const generateHttpEndpoint = (
  chainId: (typeof TYPED_CHAINS)[number]['id'],
  key: string | undefined = INFURA_KEY
) => {
  const endpoint = chainIdRpcMapping[chainId];
  if (!key || !endpoint) return http();
  return http(`${endpoint}${key}`, { batch: true });
};

export { TYPED_CHAINS as CHAINS };
export default generateHttpEndpoint;
